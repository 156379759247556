import React, { useState,useEffect,useCallback } from 'react'
import { Formik, Form } from "formik";
import axios from "axios";
import Cookies from "js-cookie";

import { FaSearch } from 'react-icons/fa';
import { Link,useHistory } from "react-router-dom";
import {
    makeStyles ,Paper, InputBase, IconButton,Snackbar,SnackbarContent,
    Backdrop,
    // CircularProgress,
} from "@material-ui/core";
import { toAbsoluteUrl } from '../../_helpers';
import SearchIcon from '@material-ui/icons/Search';
import clsx from "clsx";
import red from "material-ui/colors/red";
import { Basic } from './searchcomponent/Basic';
import { ParcelSearchResult } from './ParcelSearchResult';
import { NODE_API_URL,BASE_URL} from "./../../../env_config";
import { SwalWarn } from "../Popup";


const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 150,
      boxShadow:'0 1px 2px rgba(0, 0, 0, .14), 0 0 2px rgba(0, 0, 0, .12)!important',
      backgroundColor: '#fff',
      // border:'solid 1px #424242',
      height: '36px',
      borderRadius:"25px"
    },
    input: {
      marginLeft: 8,
      flex: 1,
      fontWeight: 500,
      color : "#8798AD"
    },
    iconButton: {
      padding: 5,
      color : "#8798AD"
    },
    divider: {
      width: 1,
      height: 28,
      margin: 4,
    },
    error: {
      backgroundColor: red[600],
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor:"#ffffffe6!important"
      },
      noScroll: {
          overflow: 'hidden'
      }
}));

export const ParcelDiamSearch = ({userinfo,language,conversionrate,symbol}) => {

    const history = useHistory();
    const classes = useStyles();
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [msgOnResponse,setMsgOnResponse] = useState()
    const [pathName,setPathName] = useState('')
    const [parcelResultOpen, setParcelResultOpen] =useState(true);
    const [parcelSearchOpen, setParcelSearchOpen] =useState(true);
    const [search,setSearch] = useState()
    const [openLoader, setOpenLoader] =useState(false);
    const [isSubmitting, setIsSubmitting] =useState(false);
    const [resultData,setResultData]=useState([])
    const [recomData,setRecomData]=useState([])
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [loading, setLoading] =useState(false);
    const [colorErr, setColorErr] =useState(false);
    const [minCaratErr, setMinCaratErr] =useState(false);
    const [clarityErr, setClarityErr] =useState(false);
    const [rangeErr, setRangeErr] =useState(false);
    const [caratErr, setCaratErr] =useState(false);
    const [pcsErr, setPcsErr] =useState(false);
    const [dimondType, setDiamondType] =useState('');
    const [windowSize, setWindowSize] =useState(0);
    const handleWindowResize = useCallback(event => {
        setWindowSize(window.innerWidth);
    }, []);
    useEffect(() => {
        // console.log("resultData",resultData)
        if( window.innerWidth > 760) {
            setParcelResultOpen(true)
            setParcelSearchOpen(true)
        } else {
            setParcelResultOpen(false)
            setParcelSearchOpen(true)
        }
        
    },[])
    useEffect(() => {
        window.DataParcel = null
        if(window.location.pathname === "/parcel-diamond"){
            setDiamondType("PN")
        }
        if(window.location.pathname === "/lab-parcel-diamond"){
            setDiamondType("PL")
        }
    },[])

    function handleClose(event, reason) {
        setAlertOpen(false);
    }


    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }

    const initialValues = {
        "shape":window.DataParcel && window.DataParcel.shape ? window.DataParcel.shape : "-",
        "range_value_from":window.DataParcel && window.DataParcel.range_value_from ?`${window.DataParcel.range_value_from}` : 1,
        "range_value_to":window.DataParcel && window.DataParcel.range_value_to ? `${window.DataParcel.range_value_to}` : 63,
        "color":window.DataParcel && window.DataParcel.color ? window.DataParcel.color : "",
        "clarities":window.DataParcel && window.DataParcel.clarities ? window.DataParcel.clarities : "",
        "pcs_value":window.DataParcel && window.DataParcel.pcs_value ? window.DataParcel.pcs_value : "",
        "carat_value":window.DataParcel && window.DataParcel.carat_value ? window.DataParcel.carat_value : "",
        "diamond_type":dimondType || "PN",
        "type":window.DataParcel && window.DataParcel.type ? window.DataParcel.type : "carat",
        "cx_country":userinfo.country,
        }
    
    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(true);
        setIsSubmitting(true)
    };

    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
        setIsSubmitting(false)
    };

    function handelRest(newValue) {
        // setParcelResultOpen(false)
        // setParcelResultOpenAnim(0)
        setTimeout(() => {
            window.location.reload(true);
        }, 400);
    }

    const SearchParcelById = () => {
        if(search){
          const finalData = {
            "parcelId": search.split('-')[1],
            "cx_country":userinfo.country,
          }
          axios({
            method: "get",
            url: `${NODE_API_URL}/INTL/searchParcelById`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            params: finalData,
          })
          .then(function(res) {
            if(res && res.data){
                const dataa = JSON.stringify(res.data.data)
                disableLoading();
                if(window.location.pathname === "/parcel-diamond" && res.data.data.diamond_type === "PL" ){
                    setPathName("/lab-parcel-diamond")
                    setMsgOnResponse("Lab-Grown Parcel Diamond")
                    setSwalWarnOpen(true)
                    const alertMessage = (
                        <div>
                          Please visit{' '}
                          <Link className='text-success' to='/parcel-diamond'>
                            Lab-Grown Parcel Diamond
                          </Link>{' '}
                          to search for Lab Parcel Stock.
                        </div>
                      );
                    setAlertMessage(alertMessage);
                }else if (window.location.pathname === "/lab-parcel-diamond" && res.data.data.diamond_type === "PN"){
                    setPathName("/parcel-diamond")
                    setMsgOnResponse("Natural Parcel Diamond")
                    setSwalWarnOpen(true)
                    const alertMessage = (
                        <div>
                          Please visit{' '}
                          <Link className='text-primary' to='/parcel-diamond'>
                            Natural Parcel Diamond
                          </Link>{' '}
                          to search for Natural Parcel Stock.
                        </div>
                      );
                    setAlertMessage(alertMessage);

                }else{
                    setResultData(JSON.parse(dataa))
                }
                window.scrollTo(0, 0)
                setSearch('')
            }
            else{
              disableLoading();
              setSearch('')
            }
          })
          .catch(function(res) {
            if(res.response.data.msg === "No Record Found"){
                disableLoading();
                setAlertOpen(true);
                setSearch('')
            }
            if(res.response.data.message === "Please Provide valid req body"){
                disableLoading();
                setAlertOpen(true);
                setSearch('')
            }
            if(res?.response?.data?.message === "UnAuthorized!"){
                var cookies = document.cookie.split(";");
              for (var i = 0; i < cookies.length; i++) {
                if(cookies[i] && !cookies[i].includes("view")){
                  var equals = cookies[i].indexOf("=");
                  var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              }
              
              window.location.replace(`${BASE_URL}/logout`);
            }
            disableLoading();
          });
        }
    }

    const changeUrlOnId = () =>{
        // console.log(pathName)
        history.push({ pathname: pathName})
    }
    function handleSearch(values) {
        // console.log(values)
        // console.log(values)
        enableLoading()
        // const finalData = `${};
        axios({
            method: "POST",
            url: `${NODE_API_URL}/INTL/helperSearch`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: values,
        })
        .then(function(res) {
            if(res && res.data){
                // console.log(res.data)
              const dataa = JSON.stringify(res.data.data)
              disableLoading();
              setResultData(JSON.parse(dataa))
              setRecomData(res.data.recommendations||[])
              window.scrollTo(0, 0)
          }
        }).
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    }
    // console.log(resultData,"resultData")
    return (
        <>
            <div className={`row ${dimondType === "PN" ? "natTab" : "labTab" }`} >
                {parcelSearchOpen ?
                <div className={`${parcelResultOpen ? "col-md-4" : "col-md-12"}`} style={{maxHeight:"89vh"}}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        // validationSchema= {Schema}
                        onSubmit={async (values, { setSubmitting }) => {
                            // enableLoading();
                            window.DataParcel = values;
                            // console.log(values)
                            if(values.range_value_from ==='' || values.range_value_to ===''){
                                setRangeErr(true)
                            }
                            if(values.color ===''){
                                setColorErr(true)
                            }
                            if(values.clarities ===''){
                                setClarityErr(true)
                            }
                            if ( values.carat_value ==='' && values.pcs_value ===''){
                                setCaratErr(true)
                            }
                           
                            if (values.shape === '' || values.range_value_from ==='' || values.range_value_to ===''|| values.color ==='' || values.clarities ==='' || ( values.carat_value ==='' && values.pcs_value ==='') || pcsErr || minCaratErr){
                                    return
                            } else{
                                Object.keys(values).forEach((key) => {
                                    if (
                                        values[key] === "" ||
                                        values[key] === null ||
                                        values[key].length === 0
                                    ) {
                                        delete values[key];
                                    }
                                });
                                setTimeout(() => {
                                    setRangeErr(false)
                                    setColorErr(false)
                                    setClarityErr(false)
                                    setCaratErr(false)
                                    handleSearch(values);
                                    
                                    setParcelResultOpen(true)
                                    if( window.innerWidth > 700) {
                                        setParcelResultOpen(true)
                                        setParcelSearchOpen(true)
                                    } else {
                                        setParcelResultOpen(true)
                                        setParcelSearchOpen(false)
                                    }
                                }, 1000);
                            }
                        }}
                        >
                        {(formik, handleSubmit) => (
                            <>
                                <Form>
                                    <div className="card card-custom card-sticky">
                                        <div className="card-header align-items-center ribbon ribbon-top">
                                            {/* <div className="ribbon-target bg-warning" style={{top:"-2px",right:"20px"}} >Beta</div> */}
                                            <div className="card-title text-primary">{dimondType === "PN" ? language.PARCEL_DIAMONDS_NATURAL:language.PARCEL_DIAMONDS_LAB}{language.PARCEL_DIAMONDS_PARCEL_DIAMONDS}</div>
                                            { window.innerWidth > 700 ? 
                                                null
                                            :
                                                <div className="card-toolbar p-5 text-right sticky-top">
                                                    <span onClick={()=>{setParcelResultOpen(true); setParcelSearchOpen(false);}}><FaSearch /> Go to Result</span> 
                                                </div>
                                            }
                                            <Paper className={classes.root}>
                                                <InputBase
                                                    className={`${classes.input} ${isSubmitting ? "cursor-na" : ""}`}
                                                    placeholder={language.SEARCHBAR_PARCEL_PLACEHOLDER}
                                                    inputProps={{ 'aria-label': 'Search by ID' }}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    onKeyDown={(e) => e.keyCode === 13?SearchParcelById():""}
                                                    value={search}
                                                />
                                                    <IconButton className={`${classes.iconButton} ${isSubmitting ? "cursor-na" : ""}`}aria-label="Search" onClick={SearchParcelById} disabled={isSubmitting}>
                                                        <SearchIcon/>
                                                    </IconButton>
                                                {/* <Backdrop open={openLoader} >
                                                    <div className="px-5 py-3 bg-white rounded shadow-sm d-flex justify-content-center align-items-center"><CircularProgress size="30px" color="secondary" /> <span className="pl-2 font-weight-bold text-dark">Processing..</span></div>
                                                </Backdrop> */}
                                            </Paper>
                                        </div>
                                        {/* <div className="card-header sticky-top bg-white"style={{top:"50px"}}>
                                            <div className="card-title text-primary">{dimondType === "PN" ? language.PARCEL_DIAMONDS_NATURAL:language.PARCEL_DIAMONDS_LAB}{language.PARCEL_DIAMONDS_PARCEL_DIAMONDS}</div>
                                            { window.innerWidth > 700 ? 
                                                null
                                            :
                                                <div className="card-toolbar p-5 text-right sticky-top">
                                                    <span onClick={()=>{setParcelResultOpen(true); setParcelSearchOpen(false);}}><FaSearch /> Go to Result</span> 
                                                </div>
                                            }
                                        </div> */}
                                        <div className="card-body parcelSearch py-0 custom-scrollbar" style={{maxHeight:"70vh"}}>
                                            <div className="row">
                                                <div className={`${parcelResultOpen ? "col-md-12" : "col-md-5"} mb-10`}>
                                                    <Basic
                                                        colorErr={colorErr}
                                                        clarityErr={clarityErr}
                                                        rangeErr={rangeErr}
                                                        caratErr={caratErr}
                                                        pcsErr={pcsErr}
                                                        minCaratErr={minCaratErr}
                                                        formik={formik}
                                                        language={language}
                                                        setRangeErr={setRangeErr}
                                                        setColorErr={setColorErr}
                                                        setClarityErr={setClarityErr}
                                                        setCaratErr={setCaratErr}
                                                        setPcsErr={setPcsErr}
                                                        setMinCaratErr={setMinCaratErr}
                                                        dimondType={dimondType}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="card-footer shadow-none natTab text-right border-top-0 sticky-top"
                                            style={{ bottom: "0",backgroundColor:"#f4f6fc" }}
                                        >
                                            <button
                                                type="reset"
                                                onClick={handelRest}
                                                disabled={isSubmitting}
                                                className={`btn btn-text-danger btn-hover-light-danger font-weight-bold px-10 ${isSubmitting ? "cursor-na" : ""}`}
                                            >
                                                {language.PARCEL_DIAMONDS_RESET}
                                            </button>
                                            <button
                                                type="submit"
                                                className={`btn btn-${dimondType === "PN" ? "primary" : "success" } px-10  text-underline font-weight-bold ml-2 ${isSubmitting ? "cursor-na" : ""}`}
                                               // disabled={(formik.values.shape === '' || formik.values.range_value_from ==='' || formik.values.range_value_to ===''|| formik.values.color ==='' || formik.values.clarities ==='') || isSubmitting ? true : false}
                                                disabled={isSubmitting}
                                                onClick={handleSubmit}
                                            >
                                                <FaSearch className="mr-2 font-size-h5" />
                                                {isSubmitting
                                                    ? "Please wait..."
                                                    : language.PARCEL_DIAMONDS_SEARCH}
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div> :""}
                {parcelResultOpen ?
                    <div className="col-md-8 parcelResultBlock custom-scrollbar" style={{maxHeight:"89vh"}}>
                        <ParcelSearchResult
                            language={language}
                            userinfo={userinfo}
                            resultData={resultData}
                            setResultData={setResultData}
                            recomData={recomData}
                            setRecomData={setRecomData}
                            setParcelSearchOpen={setParcelSearchOpen}
                            setParcelResultOpen={setParcelResultOpen}
                            symbol={symbol}
                            conversionrate={conversionrate}
                            enableLoading={enableLoading}
                            disableLoading={disableLoading}
                        />
                    </div> : ""}
            </div>
            <Backdrop className={classes.backdrop} open={openLoader} >
                <img alt="loader" src={toAbsoluteUrl(`/media/General/loading_${dimondType === "PN" ? "white" : "lab" }.gif`)} />
            </Backdrop>
            <SwalWarn show={swalWarnOpen} message={alertMessage} onHide={() => setSwalWarnOpen(false)} onClose={() => setSwalWarnOpen(false)} msgOnOk={msgOnResponse} responseOnOk={() => changeUrlOnId()}/> 
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={alertOpen}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message={`No Stock Found`}
                />
            </Snackbar>
        </>
    )
}